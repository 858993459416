@media screen and (max-width:768px){
    .find-job-btn{
        position: unset !important;
        top: auto !important;
        right: auto !important;
        transform: unset !important;
    }
    .city-search{
        top: 25px !important;
    }
    .my-border-end{
        border: 0;
    }
    .w-lg-auto{
        width: 100%;
    }
    .browse-jobs ul {
        column-count: 3;
        -webkit-column-count: 3;
    }
    .register-img{
        position: unset;
    }
    .recent-recived h5{
        width: auto;
    }
    .job-recent-title{
        width: auto;
    }
    .offcanvas{
        height: 100% !important;
    }
  
}
@media screen and (max-width:1400px){
    .recent-recived h5{
        width: 340px;
    }
    .job-recent-title{
        width: 300px;
    }
}
@media screen and (max-width:1024px){
    .menu-right{
        left: -50px !important;
    }
    .small-chat{
        width: 400px;
    }
    .recent-recived h5{
        width: auto;
    }
    .job-recent-title{
        width: 290px;
    }
}
@media screen and (max-width:425px) {
    .browse-jobs ul {
        column-count: 2;
        -webkit-column-count: 2;
    }
    .profile-banner{
        width: 100%;
    }
    .small-chat{
        width: 350px;
    }
}
@media(max-width: 1199px){
    .employer-contactus .main-title{font-size: 38px;}
    .employer-contactus .sub-text{margin-top: 25px;}
}

@media(max-width: 991px){
    .employer-contactus .left-part{padding-bottom: 0px; margin-bottom: 0px; position: static;}
    .employer-contactus .main-section{padding-bottom: 0px;}
}

@media(max-width: 767px){
    .employer-contactus{padding-bottom: 0px !important; padding-top: 30px !important;}
    .employer-contactus .main-section{padding-bottom: 0px;}
    .employer-contactus .main-title{font-size: 26px;}
    .employer-contactus .sub-text{margin-top: 25px;}
    .employer-contactus .left-part{padding: 40px 20px 0px; margin-bottom: 0px !important; margin-top: 0px !important;}
    .employer-contactus .left-part .text-blue{font-size: 20px; margin-bottom: 10px !important;}
    /* .employer-contactus .right-section .img{width: 65px;} */
    .employer-contactus .right-part .text-secondary{padding-bottom: 20px;}
    .employer-contactus .sub-content{padding-bottom: 30px;}
    .employer-contactus .right-part{padding: 20px 20px;}
    .employer-thankyou .text-capitalize{font-size: 50px;}
    .employer-thankyou .sub-content{font-size: 18px;}
}

@media(max-width: 1024px){
    .employer-contact-us .right-section1 img{
        max-width: 300px;
        width: 100%;
    }
    .employer-contact-us .right-section2{
        display: block;
    }
}

@media(max-width: 1024px){
    .register-img{
        position: static;
    }
    .employer-contact-us .right-section2 .section-box .title-text{
        font-size: 18px;
    }
}

@media(max-width: 767px){
    .employer-contact-us .right-section2 .section-box .title-text{
        font-size: 16px;
    }
    .employer-contact-us .right-section2 .section-box{
        padding: 8px 7px;
    }
}

@media(max-width: 480px){
    .employer-contact-us .space-wrap{
        padding-right: 24px !important;
        padding-left: 24px !important;
    }
}
@media(max-width: 991px){
    .employer-login .right-part{text-align: center;}
    .employer-login .text-secondary{padding: 5px;}
    .employer-login .border-start{border-left: none !important;}
}
@media only screen and (max-width: 1600px) and (min-width: 1200px) {
    .employer-contact-us .right-section1 .title-black{font-size: 30px;}
    .employer-contact-us .border-start .text-blue{font-size: 20px;}
    .employer-contact-us .border-start .rigister-sub-title{font-size: 13px;}
    .employer-contact-us .right-section1 .text-secondary, .employer-contact-us .right-section2 .section-box .title-text{font-size: 16px;}
    .employer-contact-us .right-section2{margin-top: 0px;}
    .employer-contact-us .right-section1 .img{margin: 0; width: auto; max-width: 430px;}
    .employer-contact-us{padding-top: 20px !important; padding-bottom: 20px !important;}
}
@media only screen and (max-width: 1200px) and (min-width: 1024px) {
    .employer-contact-us .right-section1 .img{max-width: 400px; width: 100%;}

    .employer-contact-us .right-section2{display: block;}
}
 


