:root {
    --primary-blue: #2457A8;
}

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body {
    font-family: 'Poppins', sans-serif;
    background-color: #F8F9FA;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap');

body select::-webkit-scrollbar {
    width: 4px;
}

body select::-webkit-scrollbar-thumb {
    background: #DBE5ED;
    border-radius: 4px
}

.nav-bar {
    z-index: 1000;
}

.nav-bar .menu-right {
    left: -40px !important;
}

.nav-bar-a a:hover {
    color: #2457A8 !important;
}

.logo {
    height: 2.5rem;
}

.text-blue {
    color: #2457A8 !important;
}

.text-black {
    color: #000000 !important;
}

.text-dark-grey {
    color: rgb(99, 99, 99) !important;
}

.job-detail-sticky {
    top: 68px;
    z-index: 99;
}

.fw-300 {
    font-weight: 300;
}

.fw-400 {
    font-weight: 400;
}

.fw-500 {
    font-weight: 500;
}

.fw-600 {
    font-weight: 600;
}

.fw-700 {
    font-weight: 700;
}

.fw-800 {
    font-weight: 800;
}

.fw-900 {
    font-weight: 900;
}

.login-form {
    padding: 50px 60px 10px 60px;
}

.bg-blue {
    background: #2457A8 !important;
}

.bg-blue:hover {
    background: #fff !important;
    border-color: #2457A8 !important;
    color: #2457A8 !important;
}

.radius-10 {
    border-radius: 10px;
}

.icon-size {
    height: 1rem;
}

.z-index {
    z-index: 10000000;
}

.search-icon {
    width: 20px;
}

.searchbar-headers .rbt-input-main {
    border-radius: 0;
    box-shadow: none !important;
}

.nav-bar .dropdown-toggle::after {
    background-image: url('../img/drop-arrow.svg');
    height: 24px;
    width: 24px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    border: none;
}

.nav-bar .dropdown-toggle:hover::after {
    border-radius: 50%;
    background-color: rgb(231, 226, 226);
}

.footer-link img {
    height: 2.2rem;
}

.footer {
    background: rgb(237 238 242 / 30%);
}

.list-style-none {
    list-style: none;
}

.overflow-unset {
    overflow: unset;
}

.footer a:hover {
    color: #2457A8 !important;
}

.fs-11 {
    font-size: 11px;
}

.fs-12 {
    font-size: 12px;
}

.fs-13-5 {
    font-size: 13.5px;
}

.fs-18 {
    font-size: 18px;
}

.fs-20 {
    font-size: 20px;
}

.fs-22 {
    font-size: 22px;
}

.fs-24 {
    font-size: 24px;
}

.fs-28 {
    font-size: 28px;
}

.fs-30 {
    font-size: 30px;
}

.lh-0 {
    line-height: 0px;
}

.lh-30 {
    line-height: 30px;
}

.mb-40 {
    margin-bottom: 40px;
}

.ms-15 {
    margin-left: 20px;
}

.gap-12 {
    gap: 12px;
}

.employer-header {
    padding: 90px 0;
}

.ml-20 {
    margin-left: 20px;
}

.bg-img-hero {
    border-bottom-left-radius: 750px;
    background: rgba(36, 87, 168, 0.8);
    background: linear-gradient(0deg, rgba(36, 87, 168, 0.8), rgba(36, 87, 168, 0.8)), url('../img/recruitment.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 750px;
    z-index: -10;
}

.company-logo ul li img {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    height: 2.5rem;
    width: 7rem;
    object-fit: contain;
    mix-blend-mode: darken;
}

.radius-2 {
    border-radius: 2px;
}

.employer-login-img {
    height: 20rem;
    object-fit: contain;
}

.user-img {
    height: 3rem;
}

.profile-number {
    color: #F82929;
}

.profile-number-bg {
    background: rgb(248 41 41 / 20%);
}

.head-jobs img {
    height: 2rem;
    width: 2rem;
}

.active-job {
    background: #2BAE92;
}

.new-application {
    background: #9599D6;
}

.candidate-reviewed {
    background: #FFBF87;
}

.shortlisted {
    background: #2457A8;
}

.recent-job .card-body {
    background: #ECEDF2;
}

.fs-13 {
    font-size: 13px;
}

.fs-14 {
    font-size: 14px;
}

.fs-15 {
    font-size: 15px;
}

.fs-16 {
    font-size: 16px;
}

.fs-10 {
    font-size: 10px;
}

.pe-34 {
    padding-right: 34px;
}

.bg-liner {
    background: transparent linear-gradient(180deg, #FFFFFF 0%, #F6FBFF 100%);
    /* height: 100vh; */
}

.post-on-color {
    color: #333 !important;
}

.fs-13 {
    font-size: 13px;
}

.recent-job .card-header {
    background: #2457A8;
}

.job-recent-title {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    width: 480px;
}

.job-recent-title:hover {
    color: #2457A7 !important;
}

.userprofile img {
    object-fit: cover;
    border: 1px solid grey;
}

.table-data thead {
    background: #2457A8;
}

.table-data img {
    height: 2.5rem;
}


.scroll-signup {
    top: 100px;
}

.my-border-end {
    border-right: 2px solid var(--bs-border-color);
}

.bg-dark-light {
    background: #E2EDFA;
}

.secondary-pill-rounded {
    background: #ebebeb;
    color: #625a5a;
}

.pagination .page-link {
    height: 45px;
    width: 45px;
}

.pagination .page-link:hover {
    background: #2457A8 !important;
    color: #fff !important;
}

.job-description a:hover {
    color: #2457A8 !important;
}

.job-overiew {
    background: #ECEDF2;
}

.job-teams .nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    border-bottom: 5px solid #2457A8;
}

.job-status .nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: #fff !important;
    background-color: #2457A8;
}

.company-details img {
    height: 4.5rem !important;
    width: 4.5rem !important;
    border: 2px solid #ced4da;
}

.company-details div {
    width: 100% !important;
    height: 100% !important;

}

.job-description div img {
    width: 100% !important;
    height: 100% !important;
    border: 1px solid grey;
}

.company-details .sb-avatar {
    height: 4.5rem !important;
    width: 4.5rem !important;
}

.object-fit-contain {
    object-fit: contain;
}

.object-fit-cover {
    object-fit: cover;
}

.experience-tag {
    background: rgb(255 149 0 / 20%);
    color: #FF9300;
}

.job-icons img {
    height: 1.5rem;
    width: 1.5rem;
}

.border-dashed {
    border-style: dashed;
}

.dashed-border {
    border: 1px dashed;
}

.w-lg-auto {
    width: auto;
}

.jd-comment-pic img {
    height: 3rem;
    width: 3rem;
}

.comment-box {
    background: #F4F5F8;
}

.profile-update {
    height: 12rem;
}

.profile-update:hover .update-pic-hover {
    background: rgba(0, 0, 0, 0.5);
    opacity: 1;
}

.update-pic-hover {
    opacity: 0;
}

.border-top-dashed {
    border-top: 1px dashed;
}

.border-color-logo {
    border-color: #2457A8 !important;
}

.left-auto {
    left: auto !important;
}

.short-interview ul li button {
    width: 50px;
    height: 50px;
}

.popover-interview {
    overflow-y: auto !important;
    overflow-x: hidden;
    min-height: auto;
    max-height: 50% !important;
    width: 35% !important;
    max-width: 40% !important;
}

.action-btns button {
    width: 40px;
    height: 40px;
}

.inset-auto {
    inset: 0px 0 auto auto !important;
    z-index: 100;
}

.cursor {
    cursor: pointer;
}

/* -----timeline----- */
.timeline::after {
    content: '';
    position: absolute;
    width: 1px;
    background-color: #bfbfbf;
    top: 0;
    bottom: 0;
    left: 5px;
}

.timeline-content::after {
    content: '';
    position: absolute;
    width: 1px;
    background-color: #bfbfbf;
    top: 0;
    bottom: 0;
    left: 5px;
}

.timeline-container::after {
    content: '';
    position: absolute;
    width: 16px;
    height: 16px;
    left: 6px;
    top: 0;
    transform: translateX(-50%);
    background-color: #E5EDFA;
    border: 1px solid #2457A7;
    border-radius: 50%;
    z-index: 1;
}

.border-end-dashed {
    border-right: 1px dashed #D3D3D3;
}

/* ---top-filter---- */
.top-filter .dropdown-toggle::after {
    border: 0;
    margin: 0;
    display: block;
}

.top-filter .dropdown-item.active,
.dropdown-item:active {
    background: #fff;
}

.post-job-timeline {
    width: 99.9%;
}

.post-job-timeline button {
    height: 28px;
    width: 28px;
}

.bg-extra-light {
    background: #ECEDF2;
}

.interview-timeline div {
    height: 32px;
    width: 32px;
}

.post-job-timeline .time-line-set {
    top: 11px;
}

.form-check-input:checked {
    background: #2457A8;
    border-color: #2457A8;
}

.chat-msg .card {
    border-color: #DBE5ED !important;
}

.chat-msg .card-header {
    border-color: #DBE5ED;
    background: #F4F5F8;
}

.chat-msg .card-body {
    background: #F9FAFC;
    height: 55vh;
    overflow-y: scroll;
}

.chat-msg .card-body::-webkit-scrollbar {
    width: 4px;
}

.chat-msg .card-body::-webkit-scrollbar-thumb {
    background: #DBE5ED;
    border-radius: 4px
}

.chat-inbox .form-control {
    border-color: #DBE5ED;
    background: #F9FAFC;
}

.chats .chat-inbox .single-chat {
    border-color: #DBE5ED !important;
    background: #F9FAFC;
}

/* .chat-msg{
    background: #F9FAFC;
    min-height: 70vh;
} */
.chats .chat-inbox .single-chat ul li img {
    height: 3rem !important;
    width: 3rem !important;
}

.chats .chat-inbox .single-chat ul li h6 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    /* width: 200px; */
}

/* .chats .chat-inbox .single-chat ul li span {
   background: #2457A8;
} */
.chats .chat-inbox .single-chat ul li p {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    /* width: 200px; */
}

.chat-msg .card-header img {
    height: 3.5rem;
    width: 3.5rem;
}

.sends-btn label {
    height: 50px;
    width: 50px;
}

.sends-btn button {
    height: 50px;
    width: 50px;
}

.user-msg-h {
    overflow-y: scroll;
    height: 68vh;
}

.user-msg-h::-webkit-scrollbar {
    width: 4px;
}

.user-msg-h::-webkit-scrollbar-thumb {
    background: #DBE5ED;
    border-radius: 4px
}

.user-msg-h::-webkit-scrollbar-track {
    background: #F9FAFC;
}

.dropdown-list::-webkit-scrollbar {
    width: 4px;
}

.dropdown-list::-webkit-scrollbar-thumb {
    background: #DBE5ED;
    border-radius: 4px
}

.dropdown-list::-webkit-scrollbar-track {
    background: #F9FAFC;
}

.z-index-up {
    z-index: 100;
}

.interview-timeline {
    top: 20px;
}

.search-bar .rbt-input {
    box-shadow: none;
    border: 0;
    /* text-transform: capitalize; */
    font-size: 1.25rem;
    background: transparent;
}

.search-bar .rbt-menu {
    margin-top: 8px;
}

.rbt-menu::-webkit-scrollbar {
    width: 4px;
    background: #fff;
    border-radius: 100px;
}

.rbt-menu::-webkit-scrollbar-thumb {
    background: #DBE5ED;
    border-radius: 4px
}

.chips-skill span {
    display: none;
}

.chips-skill .rbt-input-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.multiselect-arrow .rbt-input-wrapper {
    position: relative;
    padding-right: 25px !important;
}



.multiselect-arrow .rbt-input-wrapper::after {
    content: '';
    position: absolute;
    right: 5px;
    top: 50%;
    width: 12px;
    height: 12px;
    background-image: url('../img/down-arrow2.svg');
    background-size: contain;
    background-repeat: no-repeat;
    transform: translateY(-50%);
    pointer-events: none;
}

.chips-skill .rbt-input-wrapper .rbt-token-removeable .rbt-token-label {
    margin-right: 5px;
    font-weight: 500;
    font-size: 14px;
}

.chips-skill .rbt-input-wrapper .rbt-token-removeable {
    background: #E2EDFA;
    color: #2457A8;
    border-radius: 20px;
    padding: 2px 10px;
    margin: 4px 8px 4px 0;
}

.chips-skill .rbt-input-wrapper .rbt-token {
    display: flex;
    align-items: center;
}

.chips-skill .rbt-input-wrapper .rbt-token .rbt-token-remove-button {
    font-size: 10px;
    box-shadow: none;
}

.chips-skill .rbt-input-multi {
    border-radius: 2px;
}

/* .chips-skill .rbt-input-wrapper input {
    text-transform: capitalize;
} */

.rbt-input-main {
    border-radius: 2px;
    box-shadow: none !important;
}

.toggle-new {
    display: inline-block;
    position: relative
}

.toggle-new label {
    position: absolute;
    left: 25px;
    top: 5px;
    color: #797979;
    cursor: pointer;
}

.toggle-new .form-check-input:checked+label {
    left: unset;
    right: 25px;
    color: #2BAE92;
    line-height: 1.3;
    cursor: pointer;
}

.form-switch .form-check-input {
    background-color: #ECEDF2;
    border: none;
    width: 95px;
    height: 23px;
    cursor: pointer;
}

.form-switch .form-check-input:checked {
    background-repeat: no-repeat;
    background-color: #d4efe9;
    cursor: pointer;
}

.form-switch .form-check-input:focus {
    outline: none;
    box-shadow: none;
    cursor: pointer;
}

.dropdown-list {
    max-height: 500px;
    overflow-x: auto;
}

.active-jobs p {
    height: 1rem;
    width: 1rem;
}

.small-chat {
    right: 10%;
    z-index: 1000;
    width: 400px;
}

.small-chat .card-body {
    height: 35vh;
    overflow-y: scroll;
}

.scroll::-webkit-scrollbar {
    width: 4px;
}

.scroll::-webkit-scrollbar-thumb {
    background: #DBE5ED;
    border-radius: 4px;
}

.review-profile img {
    border: 2px solid #dee2e6;
}

.dashboard-top-searchbar {
    width: auto;
}

.dashboard-top-searchbar input,
select {
    border-right: 0;
    border-radius: 0;
    border-color: #ced4da !important;
    font-weight: 500;
}

.dashboard-top-searchbar .rbt-input-hint {
    border: 0;
}

.chips-skill .rbt-menu a {
    font-weight: 500;
    color: #000;
}

/* ---contact-us---- */
.contact-bg-blue {
    background: #2457A8;
}

.contact-bg-blue ul li i {
    width: 40px;
    height: 40px;
}

.pop-apply .modal-content {
    width: 425px;
    margin: 0 auto;
}

.register-img {
    position: sticky;
    top: 100px;
}

.recent-recived h5 {
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    width: 400px;
}

.header-offcanvas {
    height: 160px !important;
    z-index: 100000;
}

.interview-offcanvas {
    width: 450px !important;
}

.captcha {
    margin: 0 auto;
    width: 100%;
    margin-bottom: 1rem;
    transform: scale(0.92);
}

.fa-x-twitter::before {
    content: "\e61b";
    /* Unicode for the Twitter icon in Font Awesome */
    /* Add any additional styling you want */
    color: blue;
}

.fa-custom-icon::before {
    content: "";
    display: inline-block;
    width: 14px;
    /* Adjust the width and height as needed */
    height: 14px;
    background-image: url('../img/Twitter-new.svg');
    /* Use your own icon image or SVG */
    background-size: cover;
    color: blue;
}

.fa-custom-insta::before {
    content: "";
    display: inline-block;
    width: 19px;
    /* Adjust the width and height as needed */
    height: 19px;
    background-image: url('../img/instagram.svg');
    /* Use your own icon image or SVG */
    background-size: cover;
    color: blue;
}

.fa-custom-insta {
    vertical-align: middle;
}

.soical_share_icon .fa-linkedin {
    color: #007bb5;
}

.soical_share_icon .fa-twitter {
    color: #55ACEE;
}

.soical_share_icon .fa-facebook {
    color: #3B5998;
}

.soical_share_icon .fa-envelope {
    color: #e84f4a;
}

.soical_share_icon .fa-copy {
    color: green;
}

.fa-other-url-icon::before {
    content: "";
    display: inline-block;
    width: 20px;
    /* Adjust the width and height as needed */
    height: 20px;
    background-image: url('../img/browser.svg');
    /* Use your own icon image or SVG */
    background-size: cover;
}


@media screen and (max-width: 480px) {
    .captcha {
        width: 100%;
        transform: scale(0.96);
    }
}

@media screen and (max-width: 425px) {
    .captcha {
        width: 100%;
        transform: scale(0.82);
    }
}

@media screen and (max-width: 375px) {
    .captcha {
        width: 100%;
        transform: scale(0.72) !important;
    }
}

@media screen and (max-width: 375px) {
    .captcha {
        width: 100%;
        float: left;
        transform: scale(0.96);
    }
}



.interview-list-item {
    opacity: .6;
}

body p {
    text-indent: inherit !important
}

.readonly-input {
    background-color: #f0f0f0;
    color: #333;
}

p span span span {
    display: none;
}

p span span {
    padding-right: 7px;
    padding-left: 18px;
}

p.MsoListParagraphCxSpFirst,
p.MsoListParagraphCxSpMiddle,
p.MsoListParagraphCxSpLast {
    padding-left: 30px;
    margin-bottom: 0;
}

p.MsoListParagraphCxSpFirst span span,
p.MsoListParagraphCxSpMiddle span span,
p.MsoListParagraphCxSpLast span span {
    position: absolute;
    left: 15px;
}

.job_type_sec .icon-size {
    height: 1.3rem;
}

.sorting-filter {
    display: flex;
    justify-content: right;
}

.sorting-filter .dropdown-toggle::after {
    display: none;
}

.sorting-filter label {
    padding: 0 6px 0 0;
    font-size: 14px;
    line-height: 28px;
    font-weight: 600;
}

.filter-select {
    width: calc(100% - 410px);
}

.new-application-sec .sorting-filter {
    display: none !important;
}

@media screen and (max-width: 991px) {
    .filter-select {
        width: 100%;
    }

    .me-2.mb-1.sorting-filter {
        margin-top: 15px;
        justify-content: flex-start;
    }

    .sorting-filter {
        justify-content: start;
    }
}

.employer-contactus {
    padding-bottom: 0px !important;
}

.employer-contactus .main-title {
    font-size: 50px;
    line-height: 125%;
    text-align: center;
}

.employer-contactus .sub-text {
    text-align: center;
}

.employer-contactus .main-section {
    padding-bottom: 30px;
}

.employer-contactus .right-section {
    display: flex;
    align-items: flex-start;
}

.employer-contactus .right-section .img {
    width: 50px;
    margin-right: 16px;
}

.employer-contactus .sub-content {
    padding-bottom: 60px;
    font-size: 18px;
}

.employer-contactus .right-part {
    border-radius: 0px 0px 8px 8px;
    background: #F3F4F6;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.10);
    padding: 30px 60px;
}

.employer-contactus .left-part {
    padding: 50px 40px;
    padding-top: 100px;
}

.employer-contactus .left-part .text-blue {
    margin-bottom: 8px !important;
}

.employer-contactus .right-part .btn {
    text-align: center;
    padding: 10px 90px;
    margin: auto;
    border-radius: 50px;
    font-size: 20px;
}

.employer-contactus .right-part .invalid-feedback-msg {
    text-align: center;
    font-size: 16px;
    padding-top: 10px;
    color: #dc3545;
}

/* .employer-contactus .row{align-items: center;} */
.employer-contactus .sub-title {
    color: #464444;
    margin-bottom: 5px !important;
    text-align: center;
}

.rigister-sub-title {
    margin-bottom: 35px !important;
    color: black !important;
    font-style: italic;
    font-size: 15px;
}

.employer-thankyou .text-capitalize {
    text-transform: capitalize !important;
    color: #2457A8 !important;
    font-size: 70px;
}

.employer-thankyou .back_to_home a {
    background: #2457A8 !important;
    border-radius: 50px;
    font-weight: 500;
    color: white;
    padding: 10px 20px;
    text-decoration: none;
}

.employer-thankyou {
    min-height: calc(100vh - 208px);
    display: flex;
    align-items: center;
    margin: auto;
}

.employer-thankyou .text-center {
    width: 100%;
    margin: auto;
}

.employer-thankyou .sub-content {
    font-size: 18px;
    margin-bottom: 50px !important;
    margin-top: 10px;
}

.employer-thankyou .social-media {
    background: #fff;
    border-radius: 10px;
    border: 1px solid #d8d3d3;
    padding: 10px 0px 10px 0px;
    max-width: 250px;
    margin: auto;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -30px;
}

.employer-thankyou .social-media .fa-linkedin:before {
    font-size: 34px;
}

.employer-thankyou .social-media .fa-facebook:before {
    font-size: 34px;
}

.employer-thankyou .social-media .fa-instagram:before {
    font-size: 34px;
}

.employer-thankyou .social-media .fa-pinterest {
    font-size: 34px;
    color: #E60023;
}

.employer-thankyou .social-media .text-capitalize {
    font-size: 0px;
}

.employer-thankyou .social-media .fa-custom-icon::before {
    width: 24px;
    height: 24px;
}

.employer-thankyou .social-media .fa-custom-insta::before {
    width: 32px;
    height: 32px;
    vertical-align: top;
}

.employer-thankyou .social-media h4 {
    font-size: 18px;
    font-weight: 500 !important;
    margin-bottom: 15px !important;
}

.employer-thankyou .social-media a {
    margin: 0px 10px;
}

.employer-thankyou {
    position: relative;
}

.employer-thankyou img {
    margin-bottom: 35px;
    width: 75px;
}

.employer-thankyou .text-center .thank-you-title {
    margin-bottom: 0 !important;
}

.employer-contact-us .right-section1 .title-black {
    color: var(--bs-body-color);
    font-size: 34px;
}

.employer-contact-us .right-section1 .img {
    margin: 20px 20px;
}

.employer-contact-us .right-section2 .section-box {
    border: 1px solid #6c757d;
    padding: 10px 15px;
    margin: 20px 20px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.employer-contact-us .register-img {
    text-align: center;
}

.employer-contact-us .right-section2 .section-box .img {
    max-width: 60px;
    margin-right: 15px;
}

.employer-contact-us .right-section2 .section-box .title-text {
    font-size: 18px;
    margin-bottom: 5px !important;
}

.employer-contact-us .right-section2 .section-box .sub-content {
    font-size: 14px;
    margin: 0px !important;
}

.employer-contact-us .right-section1 .text-secondary {
    font-size: 18px;
}

.employer-contact-us .right-section2 {
    display: flex;
    margin-top: 40px;
}

.employer-contact-us .right-section2 .section-box .text-wrap {
    text-align: left;
}

.employer-contact-us .right-section1 .text-secondary {
    max-width: 600px;
    text-align: center;
    margin: auto;
    margin-bottom: auto;
    width: 100%;
}

.employer-contact-us .re-capcha {
    text-align: center;
}

.employer-contact-us .capcha-block {
    display: inline-block;
}

.employer-register-link {
    margin-top: 20px;
    text-align: center;
    font-size: 14px;
}

.employer-contact-us .invalid-feedback-msg {
    text-align: center;
    font-size: 16px;
    padding-top: 10px;
    color: #dc3545;
}

.employer-restriction .text-capitalize {
    text-transform: capitalize !important;
    color: #2457A8 !important;
    font-size: 52px;
    line-height: 1 !important;
}

.employer-restriction .back_to_home a {
    background: #2457A8 !important;
    border-radius: 50px;
    font-weight: 500;
    color: white;
    padding: 10px 20px;
    text-decoration: none;
}

.employer-restriction {
    min-height: calc(100vh - 208px);
    display: flex;
    align-items: center;
    margin: auto;
}

.employer-restriction .text-center {
    max-width: 1000px;
    width: 100%;
    margin: auto;
}

.employer-restriction .sub-content {
    font-size: 26px;
}

.employer-btn {
    color: #2457A8 !important;
    background-color: #d3d4d5;
    border-color: #c6c7c8;
}

.employer-btn:hover {
    color: #000 !important;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
    ;
}


/* comman footer */

.foot-ipad-show {
    display: none;
}

.fa-custom-icon::before {
    content: "";
    display: inline-block;
    width: 16px;
    /* Adjust the width and height as needed */
    height: 16px;
    background-image: url('../img/Twitter-new_icon.svg');
    /* Use your own icon image or SVG */
    background-size: cover;
    color: blue;
}

.fa-custom-insta::before {
    content: "";
    display: inline-block;
    width: 19px;
    /* Adjust the width and height as needed */
    height: 19px;
    background-image: url('../img/instagram.svg');
    /* Use your own icon image or SVG */
    background-size: cover;
    color: blue;
}

.fa-custom-insta {
    vertical-align: middle;
}

.fa-facebook {
    color: #2457A8;
    font-size: 20px;
}

.fa-linkedin {
    color: #007bb5;
    font-size: 20px;
}

.fa-pinterest {
    color: #E60023;
    font-size: 20px;
}

@media only screen and (max-width: 1199px) {
    .hide-ipad {
        display: none;
    }

    .foot-ipad-show {
        display: block;
        margin-top: 20px;
    }
}

@media only screen and (max-width: 991px) {
    .hide-ipad {
        display: block;
    }

    .foot-ipad-show {
        display: none;
    }
}

.message-icon {
    width: 35px
}

.border-hover {
    border: 1px solid transparent;
}

.border-hover:hover {
    border: 1px solid #2457A8;
}

.button-custom-outline {
    border: 1px solid #2457A8;
    color: #2457A8
}

.button-custom-outline:hover {
    background-color: #2457A8 !important;
    color: #fff;
}


.footer-icon:hover {
    opacity: 0.7;
}


.bg-blue-non-hover {
    background: #2457A8 !important;
}

.icon-color {
    color: var(--primary-blue);
}

.icon-color:hover {
    opacity: 0.6;
}

.border-bottom-hover:hover {
    border-bottom: 1px solid;
}

.text-color-hover {
    color: var(--primary-blue);
}

.text-color-hover:hover {
    color: var(--primary-blue);
    opacity: 0.8;
}

.text-color {
    color: var(--primary-blue)
}

.text-color:hover {
    color: var(--primary-blue);
    opacity: 0.8;
}

.fa-trash-can {
    color: #f00
}

.fa-trash-can:hover {
    opacity: 0.7;
}

.job-card-icon:hover {
    text-decoration: underline;
}

.job-card-icon:hover i {
    opacity: 0.6;
}

.candidate-card-icon:hover span {
    text-decoration: underline;
}

.candidate-card-icon:hover i {
    text-decoration: none;
    opacity: 0.6;
}

.btn-hover-opacity:hover {
    opacity: 0.6;
}

.dashboard-box {
    border: 1px solid #D9D9D9;
    border-radius: 10px;
    background: #FFFFFF;
    padding: 0px;
}


.section-title {
    font-size: 22px;
    font-weight: 700;
    line-height: 30px;
    margin-bottom: 10px;
}


.search-box {
    padding: 24px 15px 0px;
}


.search-history:hover {
    text-decoration: underline !important;
    opacity: 0.7;
}

.dashboard-box .input-search input {
    padding-left: 2.5rem;
}

.dashboard-box .location-search .rbt-input-main {
    border-radius: 30px !important;
    padding-right: 48px !important;
}

.dashboard-box .multiselect-rounded .rbt-input-multi {
    border-radius: 30px !important;
    padding: 5px 10px 5px 15px !important;
}

.dashboard-box .location-search .form-control {
    padding: 4px 15px;
}

.location-search .rbt-aux {
    position: absolute !important;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
}

.location-search .rbt-aux .rbt-close {
    border-radius: 0px;
    opacity: 1;
    background-color: white;
}


.bg-light-blue {
    background: #e0e0e2;
}



.dashboard-text-dark {
    color: #545454;

}

.recent-jobs-title {
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.recent-jobs {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
}

.recent-job-card {
    width: 32.4%;
    height: 125px;
    padding: 9px 12px 9px 12px;
    border-radius: 10px;
    border: 1px solid #D9D9D9;
    background: #FFFFFF;
    transition: box-shadow 0.3s ease;
}

@media screen and (max-width: 1200px) {
    .recent-job-card {
        width: 49%;
    }
}

@media screen and (max-width: 992px) {
    .recent-job-card {
        width: 100%;
    }
}

.recent-job-card:hover {
    box-shadow: 0px 0px 20px 0px #00000033;

}

.quick-access {
    margin: 80px 0px 60px;
}

.quick-access-btn {
    width: 120px;
    height: 90px;
    border: 1px solid #BEBEBE;
    background: #fff;
    display: flex;
    justify-content: center;
    text-align: center;
    padding: 10px;
    transition: transform 0.5s ease;
    cursor: pointer;
    box-shadow: 2px 2px 4px 0px #0000001A;
}

.quick-access-btn p {
    color: #000 !important;
}

.quick-access-btn:hover {
    transform: translateY(5px);
    border: 1px solid #2457A8;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 10px;
}


.quick-access-btn img {
    /* width: 21.5px; */
    opacity: 0.6;
}

.quick-access-btn .fa-user-gear,
.quick-access-btn .fa-user,
.quick-access-btn .fa-bookmark,
.quick-access-btn .fa-download {
    font-size: 21.5px !important;
}


.quick-access-btn:hover i {
    opacity: 0.6;
}



.custom-tab-btn {
    color: #555;
    width: fit-content;
    background-color: transparent;
    border: 0px;
    text-align: center;
    padding: 0.5rem 1rem;
    font-size: 22px;
}

.custom-tab-btn:hover {
    text-decoration: underline;
}

@media screen and (max-width: 992px) {
    .custom-tab-btn {
        font-size: 18px;
        padding: 0.25rem 0.50rem;
    }
}

.custom-tab-btn.active {
    border-bottom: 4px solid #2457A8;
}

.custom-tabs-container {
    border-bottom: 2px solid #dee2e6;
}


/* history search  */

.recent-job-card-list {
    display: flex;
    flex-wrap: wrap;
}

@media screen and (max-width: 992px) {
    .recent-job-card-list {
        justify-content: center;
    }
}

.recentjob-view-all-btn {
    margin-top: -10px;
}

.history-card {
    border: 1px solid #BEBEBE;
    background: #fff;
    max-width: 420px;
    height: 381px;
    gap: 0px;
    border-radius: 8px;
    padding: 20px;
    transition: transform 0.3s ease;
}

.history-card-body {
    height: 81%;
}

.historyList {
    list-style: none;
    padding-left: 0.5rem;
    margin-bottom: 0px;
}

.history-card .history-hr {
    border: 1.5px solid rgb(48, 46, 46) !important;
}

.history-card:hover {
    box-shadow: 0px 0px 20px 0px #00000033;

}

.history-text-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 360px;
    display: inline-block;
}

.hover-back-grey:hover {
    border-radius: 50%;
    background-color: rgb(231, 226, 226);
}

.full-height-modal .modal-content {
    height: 90vh !important
}

.btn-more {
    padding: 4px;
    border: 1px solid #2457A8;
    color: #2457A8
}

.btn-more:hover {
    padding: 4px;
    border: 1px solid #2457A8;
    background-color: #E2EDFA;
}

.resume-view {
    width: 22px;
}

/* hover class */

.hover-opc-6:hover {
    opacity: 0.8;
}

/* placeholder color */
.dark-placeholder input[type="text"]::-webkit-input-placeholder {
    color: black !important;
}

.dark-placeholder input[type="text"]:-moz-placeholder {
    color: black !important;
}

.dark-placeholder input[type="text"]::-moz-placeholder {
    color: black !important;
}

.dark-placeholder input[type="text"]:-ms-input-placeholder {
    color: black !important;
}

.dark-placeholder input[type="text"]::placeholder {
    color: black !important;
}


.secondary-outline-pill-btn {
    border: 1px solid grey;
    color: rgb(85, 85, 85);
}

.secondary-outline-pill-btn:hover {
    border: 1px solid #2457A8;
    color: #2457A8;
}

.secondary-outline-pill-btn.active {
    border: 1px solid #2457A8;
    color: #2457A8;
}

.btn-grey {
    border: 1px solid grey;
    color: black;
}

.btn-grey:hover {
    border: 1px solid grey;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

/* light blue btn */

.btn-lightblue {
    color: var(--primary-blue);
    padding: 8px 24px;
    border-radius: 50rem;
    border: 1px solid var(--primary-blue);
    text-decoration: none;
    transition: 0.2s;
    font-weight: 500;
}

.btn-lightblue:hover,
.btn-lightblue-active {
    background-color: #E2EDFA;
}

.btn-login {
    color: var(--primary-blue);
    padding: 8px 24px;
    background-color: #f8f9fa;
    font-weight: 500;
    border-radius: 50rem;
    text-decoration: none;
    text-transform: uppercase;
    transition: 0.2s;
}

.btn-login:hover,
.btn-login-active {
    background-color: #d3d4d5;
}

.draft-action .btn:hover,
.btn.show {
    background-color: #e4e6e7;
}

.text-hover-blue:hover {
    color: #2457A8 !important;
    text-decoration: underline;
}

mark {
    padding: 1px;
}


/* candidate Profile start */
#candidate-profile .accordion-collapse {
    width: 100% !important;
}


.candidate-profile-section-2 {
    padding: 0px 72px 0px 72px;
}

@media screen and (max-width: 1200px) {
    .candidate-profile-section-2 {
        padding: 0px;
    }
}

.candidate-profile-btn-more-item {
    color: rgb(52 49 49) !important;
    cursor: pointer;
}

.candidate-profile-btn-more-item:hover {
    color: black !important;
    text-decoration: underline;
}

.expandable-content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease, opacity 0.5s ease;
    opacity: 0;
}

.expandable-content.expanded {
    max-height: 5000px;
    opacity: 1;
}

/* candidate Profile end */
.w-90 {
    width: 90%;
}

.custom-tooltip .tooltip-inner {
    white-space: nowrap;
    max-width: none !important;
}

.custom-tooltip-max-200 .tooltip-inner {
    white-space: normal;
    word-wrap: break-word;
    overflow-wrap: break-word;
    max-width: 300px !important;
    text-align: left;
}


.canddiate-filter .chips-skill .rbt-input-multi {
    flex-wrap: wrap !important;
    white-space: normal !important;
}

.canddiate-filter .rbt-input-main {
    background-color: white !important;

}

#candidate-search .chips-skill .rbt-input-multi {
    flex-wrap: wrap !important;
    white-space: normal !important;
}

#candidate-search .rbt-input-main {
    background-color: white !important;

}

#candidate-search .filter-panel {
    background-color: white;
    height: fit-content;
}

#candidate-search .search-bar-padding {
    padding-right: 50px;
    padding-left: 2.5rem !important;
}

#candidate-search .filter-panel .form-control,
#candidate-search .form-select {
    padding: 4px 8px;
    font-size: .875rem;
    border-radius: 0px;
}

.docx-viewer-iframe {
    width: 100%;
    height: 100vh;
}