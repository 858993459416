@import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
@import '../node_modules/react-toastify/dist/ReactToastify.css';
@import './assets/css/style.css';
@import './assets/css/responsive.css';

#preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(45deg, #b3b1b18f, #b3b1b18c);
    z-index: 9999999;
}

#preloader #status {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

#preloader #status .spinner {
    width: 40px;
    height: 40px;
    position: relative;
    margin: 100px auto;
}

#preloader #status .spinner .double-bounce1,
#preloader #status .spinner .double-bounce2 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #007bff;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-animation: sk-bounce 2s infinite ease-in-out;
    animation: sk-bounce 2s infinite ease-in-out;
}

#preloader #status .spinner .double-bounce2 {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
}

@-webkit-keyframes sk-bounce {

    0%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    50% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes sk-bounce {

    0%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    50% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

.candidate-menu-item:hover>.candidate-menu {
    display: block;
}

.candidate-menu-item:hover>.candidates-dropdown {
    color: #2457A8 !important;
    cursor: pointer;
}

.breadcrumb {
    --bs-breadcrumb-divider: '>';
}

.Toastify__toast-container--top-right {
    z-index: 999999 !important;
}

/* messages */
.notification-count {
    position: absolute;
    top: -35%;
    right: 20px;
    cursor: pointer;
}

.fa-message {
    cursor: pointer;
}

.msg-count {
    width: 20px;
    height: 20px;
    background: #f9dada;
    border-radius: 50%;
    color: #F82929;
    font-size: 12px;
    text-align: center;
    padding: 1px;
}

.header-count {
    top: 0px;
    width: 20px;
    height: 20px;
    background: #f9dada;
    border-radius: 50%;
    position: absolute;
    color: #F82929;
    font-size: 12px;
    text-align: center;
    padding: 1px;
}

.search-bar-padding {
    padding-right: 100px;
    padding-left: 2.5rem !important;
}


.btn-cancel-grey {
    background-color: #f8f9fa;
    border: 1px solid #f8f9fa;
}

.btn-cancel-grey:hover {
    color: black;
    background-color: #d3d4d5;
    border-color: #c6c7c8;
}